// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyDVatRHDpO7IQLEQIft7BAg8qkHgxUDUGg",
    authDomain: "la-casa-del-operadordejusticia.firebaseapp.com",
    databaseURL: "https://la-casa-del-operadordejusticia.firebaseio.com",
    projectId: "la-casa-del-operadordejusticia",
    storageBucket: "la-casa-del-operadordejusticia.appspot.com",
    messagingSenderId: "108603324468",
    appId: "1:108603324468:web:d8f9de31412159a9",
    measurementId: "G-XQ9QZVLCHB"

    // apiKey: "AIzaSyB7xMVos8ZyMweJwc1odSC3uRJQ2pfZ3x8",
    // authDomain: "pruebas-casa-operador.firebaseapp.com",
    // databaseURL: "https://pruebas-casa-operador.firebaseio.com",
    // projectId: "pruebas-casa-operador",
    // storageBucket: "pruebas-casa-operador.appspot.com",
    // messagingSenderId: "1892203635",
    // appId: "1:1892203635:web:f114ac9d89826e46923e06",
    // measurementId: "G-2400H0606D"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
