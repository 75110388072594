import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';

//MODULO FUNCION ABRIR PDF

import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';


//MODULO DE FIREBASE
import { AngularFireDatabaseModule} from '@angular/fire/database';

//MODULO FIREBASE PARA AUTENTICAR

import { AngularFireAuthModule } from '@angular/fire/auth';

//MODULO API YOUTUBE

//import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';

import { HttpClientModule } from '@angular/common/http';

//MODULO PARA NAVEGADOR DENTRO DE LA APP

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

//MODULO PARA SUBIR HOJAS DE VIDA

import { AngularFireStorageModule } from '@angular/fire/storage';


//CAMARA

import { Camera } from '@ionic-native/camera/ngx';

//NOTIFICACIONES

import { FirebaseX  } from '@ionic-native/firebase-x/ngx';

//VERSION

import { AppVersion } from '@ionic-native/app-version/ngx';

//MARKET

import { Market } from '@ionic-native/market/ngx';





@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(),
     AppRoutingModule,AngularFireModule.initializeApp(environment.firebase),
     AngularFireDatabaseModule
     ,AngularFireAuthModule
     ,HttpClientModule
     ,AngularFireStorageModule
    ],
  providers: [
    FirebaseX,
    AppVersion,
    Market,
    Camera,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ,File,FileOpener,FileTransfer,DocumentViewer,FileChooser,FilePath
  //  ,YoutubeVideoPlayer
    ,InAppBrowser
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
