import { Injectable } from '@angular/core';

import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Platform } from '@ionic/angular';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class FcmServiceService {

  constructor(
    private firebase: FirebaseX,
    private platform: Platform
    ,public db: AngularFireDatabase
  ) { }

  async getToken() {
    let token;
    //alert('hello');

    if (this.platform.is('cordova')) {
      token = await this.firebase.getToken();
      console.log("cordova token",token);
    }

    if (this.platform.is('ios')) {
      token = await this.firebase.getToken();
      await this.firebase.grantPermission();
      console.log("ios token",token);
    }

  //  this.saveToken(token);
    //ELIMINAR!!!
    this.firebase.subscribe("testing");
  }

  private saveToken(token) {
    if (!token) return;

    const itemRef = this.db.list("/devices/");

    const data = {
      token,
      userId: 'testUserId'
    };

    return itemRef.push(data);
  }


  onNotifications() {
    return this.firebase.onMessageReceived();
  }

}
