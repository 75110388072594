import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { AuthenticationService } from '../app/services/authentication.service';

import { ToastController } from '@ionic/angular';
import { FcmServiceService } from '../app/services/fcm-service.service'

import { AppVersion } from '@ionic-native/app-version/ngx';

import * as firebase from 'firebase';

import { Market } from '@ionic-native/market/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  pages: Array<{title: string, icon: string, link: string}>;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
    ,private navegador : InAppBrowser
    ,public authService : AuthenticationService
    ,public toastController: ToastController
    ,private fcm: FcmServiceService
    ,private appVersion: AppVersion
    ,private market: Market
  ) {
    this.initializeApp();
    this.pages = [
      { title: 'Noticias',  icon: 'md-paper', link:'noticias' },
      { title: 'Mi perfil',  icon: 'md-card', link: "mi-perfil"},
      { title: 'Asignacion de cursos', icon: 'md-folder-open', link:"cursos" },
      { title: 'Artículos Publicitarios',  icon: 'md-warning', link:"anuncios-publicitarios" },
      { title: '¿Quienes somos?',  icon: 'md-attach', link:"quienes-somos" },
      { title: 'Revista el operador', icon: 'md-attach', link:"revista-el-operador-de-justicia" },
      { title: 'Hojas de vida',  icon: 'md-attach', link:"hojas-de-vida" },
      { title: 'Canal de YouTube',  link:"canal-de-youtube", icon: 'md-attach' },
      { title: 'CONFERENCIAS', link:"conferencias", icon: 'md-attach' },
      { title: 'Galerias de fotos', link:"galerias-de-fotos", icon: 'md-attach' },
      { title: 'Leyes',  link:"leyes", icon: 'md-attach' },
      { title: 'Biblioteca',  link:"biblioteca", icon: 'md-attach' },
      { title: 'Convenios', link:"convenios", icon: 'md-attach' },
      { title: 'Contáctanos',  icon: 'md-warning', link:"contactanos" },
    ];
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      //document.addEventListener('deviceready', this.notificationSetup, false);
      //this.notificationSetup();
    });



    var este = this;

    this.appVersion.getVersionNumber().then((res)=>{
      console.log("Version",res);
      firebase.database().ref("version").once('value').then(function(res2){
          // console.log("version2",res);
          // console.log("version BD",res2.val());
          // console.log("es la.1",res=="2.0.1");
          //alert(este.compareVersions(res, res2.val()));
          if(este.compareVersions(res, res2.val())){
            console.log("inicio");
          }else{
            alert("Es necesario actualizar la aplicación para poder continuar");
            if (este.platform.is('android')) {
              este.market.open('operador.justicia.app').then(response => {
                console.debug(response);
                  //este.platform.exitApp();
              }).catch(error => {
                console.warn(error);
              });
            }else if(este.platform.is('ios')){
              este.market.open('casa.justicia.app').then(response => {
                console.debug(response);
                  //este.platform.exitApp();
              }).catch(error => {
                console.warn(error);
              });
            }else{
              alert("Plataforma no detectada, Por favor actualice la aplicación manualmente.")
            }
                          //este.nav.setRoot(Login);
          }
        });
     }, (err)=>{
      console.log("Version error", err);
     });

     

  }


  compareVersions(installed, required) {

    var a = installed.split('.');
    var b = required.split('.');

    for (var i = 0; i < a.length; ++i) {
        a[i] = Number(a[i]);
    }
    for (var i = 0; i < b.length; ++i) {
        b[i] = Number(b[i]);
    }
    if (a.length == 2) {
        a[2] = 0;
    }

    if (a[0] > b[0]) return true;
    if (a[0] < b[0]) return false;

    if (a[1] > b[1]) return true;
    if (a[1] < b[1]) return false;

    if (a[2] > b[2]) return true;
    if (a[2] < b[2]) return false;

    return true;
}






  prueba(){
    console.log("hola");
  }

  abrirLinkMiCoope(){
    this.navegador.create('https://micoopeguadalupana.com.gt/','_self');
  }

  cerrarSesion(){
    this.authService.presentAlertConfirm();
  }

  private async presentToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 3000
    });
    toast.present();
  }

  private notificationSetup() {
    //alert('hello2')
    if(!(this.platform.is('ios') || this.platform.is('cordova'))) return;
    this.fcm.getToken();

    this.fcm.onNotifications().subscribe(
      (msg) => {
        console.log("entro notificacion");
      //  alert("notificacion entro");
        if (this.platform.is('ios')) {
        //  this.presentToast(msg.aps.alert);
        } else if(this.platform.is('cordova')) {
        //  this.presentToast(msg.body);
        }
      });
  }


}
