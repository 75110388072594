import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AngularFireAuth } from '@angular/fire/auth';
import { isNullOrUndefined } from 'util';
import { map } from 'rxjs/operators';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public afAuth : AngularFireAuth,
              public router : Router
              ,public loadingController : LoadingController
              ){}

    canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


      return this.getRespuesta();
      
  }

  async getRespuesta(){

    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Por favor espere'
    });
    await loading.present();


    var respuesta : boolean = true;

    await this.afAuth.authState.subscribe(user => {
      if (!isNullOrUndefined(user)) {
          this.router.navigate(['/noticias']);
          respuesta = false;
          loading.dismiss();
      }else{
          respuesta = true;
          loading.dismiss();
      }
    })

    return respuesta;

  }

  

  
}
