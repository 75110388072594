import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../app/guards/auth.guard';
import { auth } from 'firebase';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'noticias',
    loadChildren: () => import('./pages/noticias/noticias.module').then( m => m.NoticiasPageModule)
  },
  {
    path: 'noticia/:idNoticia',
    loadChildren: () => import('./pages/noticias/noticia/noticia.module').then( m => m.NoticiaPageModule)
  },
  {
    path: 'quienes-somos',
    loadChildren: () => import('./pages/quienes-somos/quienes-somos.module').then( m => m.QuienesSomosPageModule)
  },
  {
    path: 'revista-el-operador-de-justicia',
    loadChildren: () => import('./pages/revista-el-operador-de-justicia/revista-el-operador-de-justicia.module').then( m => m.RevistaElOperadorDeJusticiaPageModule)
  },
  {
    path: 'hojas-de-vida',
    loadChildren: () => import('./pages/hojas-de-vida/hojas-de-vida.module').then( m => m.HojasDeVidaPageModule)
  },
  {
    path: 'galerias-de-fotos',
    loadChildren: () => import('./pages/galerias-de-fotos/galerias-de-fotos.module').then( m => m.GaleriasDeFotosPageModule)
  },
  {
    path: 'galeria/:idGaleria',
    loadChildren: () => import('./pages/galerias-de-fotos/galeria/galeria-routing.module').then( m => m.GaleriaPageRoutingModule)
  },
  {
    path: 'leyes',
    loadChildren: () => import('./pages/leyes/leyes.module').then( m => m.LeyesPageModule)
  },
  {
    path: 'ley/:idLey',
    loadChildren: () => import('./pages/leyes/ley/ley-routing.module').then( m => m.LeyPageRoutingModule)
  },
  {
    path: 'biblioteca',
    loadChildren: () => import('./pages/biblioteca/biblioteca.module').then( m => m.BibliotecaPageModule)
  },
  {
    path: 'categoria-biblioteca/:idCategoriaBiblioteca',
    loadChildren: () => import('./pages/biblioteca/categoria-biblioteca/categoria-biblioteca-routing.module').then( m => m.CategoriaBibliotecaPageRoutingModule)
  },
  {
    path: 'convenios',
    loadChildren: () => import('./pages/convenios/convenios.module').then( m => m.ConveniosPageModule)
  },
  {
    path: 'convenio/:idConvenio',
    loadChildren: () => import('./pages/convenios/convenio/convenio-routing.module').then( m => m.ConvenioPageRoutingModule)
  },
  {
    path: 'canal-de-youtube',
    loadChildren: () => import('./pages/canal-de-youtube/canal-de-youtube.module').then( m => m.CanalDeYoutubePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
    , canActivate : [AuthGuard] 
  },
  {
    path: 'mi-perfil',
    loadChildren: () => import('./pages/mi-perfil/mi-perfil.module').then( m => m.MiPerfilPageModule)
  },
  {
    path: 'contactanos',
    loadChildren: () => import('./pages/contactanos/contactanos.module').then( m => m.ContactanosPageModule)
  },
  {
    path: 'cursos',
    loadChildren: () => import('./pages/cursos/cursos.module').then( m => m.CursosPageModule)
  },
  {
    path: 'conferencias',
    loadChildren: () => import('./pages/conferencias/conferencias.module').then( m => m.ConferenciasPageModule)
  },
  {
    path: 'anuncios-publicitarios',
    loadChildren: () => import('./pages/anuncios-publicitarios/anuncios-publicitarios.module').then( m => m.AnunciosPublicitariosPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
