import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';

import { Router } from '@angular/router';

import { AlertController ,LoadingController} from '@ionic/angular';
//import { map } from 'rxjs/operators';
//import { error } from 'protractor';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public isLogged : any = false;

  constructor(public afAuth:AngularFireAuth, public router:Router,public alertController: AlertController,public loadingController : LoadingController) { 
    afAuth.authState.subscribe(user => (this.isLogged = user));
  }



  //RESTABLECER

  async restablecerPassword(email){
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Espere...'
    });
    await loading.present();
    this.afAuth.sendPasswordResetEmail(email).then(() => {
      console.log('revise su correo')
      loading.dismiss();
      alert('Revisa tu correo '+email);
    }).catch((error) =>{
      console.log(error);
      loading.dismiss();
      alert('Este correo aun no esta registrado');
    })
  }

  //LOGIN

  async onLogin(user){
    try {
      return this.afAuth.signInWithEmailAndPassword(user.email,user.password);
    } catch (error) {
      console.log('Error en login',error);
    }
  }


  async onRegister(user,password){

      return this.afAuth.createUserWithEmailAndPassword(user.email,password).then(res=>{
        console.log('LOGGEADO',res);
        return res;
      }).catch(error=>{
        console.log('ERROR LOGGEO',error);
        console.log(error.message);
        if(error.message == 'The email address is already in use by another account.') this.presentAlertEmailRepeat(user.email);
        return error;
      })
    
  }



  async Reestablecer(user){
    try {
      return;
    } catch (error) {
      console.log('Error en Reestablecimiento',error);
    }
  }

    async presentAlertConfirm(){
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Confirmación',
        message: '<strong>Seguro que desea salir de la aplicación</strong>',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Cancel Exit');
            }
          }, {
            text: 'Confirmar',
            handler: () => {
              try {
                this.afAuth.signOut().then(()=>
                this.router.navigate(['/login'])
                );
                
              } catch (error) {
                console.log('Error en Logout',error);
              }
            }
          }
        ]
      });
  
      await alert.present();
    }

    async presentAlertEmailRepeat(user){
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Confirmación',
        message: '<strong>Este correo ya esta asociado a otra cuenta, ¿deseas recuperar tu contraseña?</strong>',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              try {
                this.afAuth.signOut().then(()=>
                this.router.navigate(['/login'])
                );
              } catch (error) {
                console.log('Error en Logout',error);
              }
            }
          }, {
            text: 'Confirmar',
            handler: () => {
              this.restablecerPassword(user);
            }
          }
        ]
      });
  
      await alert.present();
    }



}
